import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Logo from "../static/Full-Logo-Trans.png";
import "./NavBar.css"; // Import the CSS file for styling

const NavBar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const handleToggleMenu = () => {
		setIsOpen(!isOpen);
		if (isOpen) {
			document.body.style.overflow = "auto";
		} else {
			document.body.style.overflow = "hidden";
		}
	};

	const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
	const closeMobileMenu = () => {
		setIsOpen(false);
		document.body.style.overflow = "auto";
	};
	useEffect(() => {
		const contentElements = document.querySelectorAll(".content");
		contentElements.forEach((element) => {
			if (isOpen) {
				element.classList.add("blur");
			} else {
				element.classList.remove("blur");
			}
		});
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			if (prevScrollpos > currentScrollPos && window.innerWidth > 768) {
				document.getElementById("navbar").style.top = "0";
			} else if (window.innerWidth > 768) {
				document.getElementById("navbar").style.top = "-20vh";
			}
			setPrevScrollpos(currentScrollPos);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [isOpen, prevScrollpos]);

	return (
		<nav id="navbar" className={`navbar ${isOpen ? "active" : ""}`}>
			<img
				src={Logo}
				className="logo"
				alt="Rowin Roceao Impex"
				onClick={() => {
					window.location.href = "/";
				}}
			></img>
			<div className={`nav-links ${isOpen ? "active" : ""}`}>
				<NavLink to="/" className="nav--link" onClick={closeMobileMenu}>
					Home
				</NavLink>
				<NavLink to="/about-us" className="nav--link" onClick={closeMobileMenu}>
					About Us
				</NavLink>
				<NavLink
					to="/our-products"
					className="nav--link"
					onClick={closeMobileMenu}
				>
					Our Products
				</NavLink>
				<NavLink
					to="/contact-us"
					className="nav--link"
					onClick={closeMobileMenu}
				>
					Contact Us
				</NavLink>
			</div>
			<div className="hamburger-menu" onClick={handleToggleMenu}>
				<div className={`bar`} />
				<div className={`bar`} />
				<div className={`bar`} />
			</div>
		</nav>
	);
};

export default NavBar;
