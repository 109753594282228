import React, { useState, useEffect } from "react";

function ImageLoader({
  className,
  mainImage,
  placeholderImage,
  placeholderSaturation = 2.5, // Default saturation level
  alt,
  ...props
}) {
  const [currentImage, setCurrentImage] = useState(placeholderImage);
  const isMainImageLoaded = currentImage === mainImage;

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = mainImage;
    imageToLoad.onload = () => {
      setCurrentImage(mainImage); // Set the image source to the main image once it is loaded
    };
  }, [mainImage]);

  return (
    <>
      <img
        className={className}
        src={currentImage}
        alt={alt}
        {...props}
        style={{
          ...props.style,
          transition: "opacity 0.3s linear, filter 0.3s linear",
          filter: isMainImageLoaded ? "" : `saturate(${placeholderSaturation})`,
          opacity: isMainImageLoaded ? 1 : 0.9,
        }}
      />
      {isMainImageLoaded && (
        <div className="noise-overlay"></div>
      )}
    </>
  );
}

export default ImageLoader;
