import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop />
       <App />
    </BrowserRouter>
  </React.StrictMode>
);
