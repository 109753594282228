import React from "react";
import "./ContactUs.css";
import Footer from "../Footer";

export default function ContactUs() {
	function submitLoadingAnimation() {
		var button = document.getElementById("contact-us-button");
		button.innerHTML = "Sending...";
		button.classList.add("pulse-animation");
	}

	return (
		<div className="content" id="contact-us-content">
			<div className="top-gap" />
			<div className="contact-us-title">Contact Us: </div>
			<div className="contact-us-content">
				<div className="contact-us-column">
					<span className="contact-us-bold">OFFICE ADDRESS:</span>
					<br />
					<br />
					9HV5+WV8, Saraswathi Colony, Uppal, HYD, Telangana State, INDIA -
					500039.
					<div className="contact-us-map">
						<iframe
							title="Location Map"
							width="100%"
							height="355px"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d431.31834595625446!2d78.55935431402442!3d17.39476932680977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb999eaa92f1a5%3A0xc60de352166af381!2sROWIN%20ROCEAO%20IMPEX%20-%20Uppal!5e0!3m2!1sen!2sin!4v1698063633603!5m2!1sen!2sin"
							frameborder="0"
							marginheight="0"
							marginwidth="0"
							className="map"
						></iframe>
					</div>
					<br />
				</div>

				<div className="contact-us-column">
					To make an enquiry and to help us best serve you, please full out the
					form below. You may also email or call us. Our general response time
					is 1/2 to 2 business days.
					<div className="contact-us-form">
						<form
							action="https://formsubmit.co/baig@rowin-roceao.com"
							method="POST"
							onSubmit={submitLoadingAnimation}
						>
							<input
								type="hidden"
								name="_next"
								value="https://www.rowin-roceao.com/"
							/>
							<input type="hidden" name="_captcha" value="false"></input>
							<input
								type="hidden"
								name="_cc"
								value="admin@rowin-roceao.com"
							></input>
							<div class="contact-us-name">
								<label for="name" className="form-label">Name <div className="form-required">(required)</div></label>
								<input type="text" name="name" id="name" required />
							</div>

							<div class="contact-us-email">
								<label for="email" className="form-label">Email <div className="form-required">(required)</div></label>
								<input
									type="email"
									name="email"
									placeholder="yourmail@email.com"
									id="email"
									required
								/>
							</div>
							<div class="form-group" id="contact-us-message">
								<label for="message" className="form-label">Message <div className="form-required">(required)</div></label>
								<textarea
									placeholder="Your message here..."
									class="form-control"
									name="message"
									rows="10"
									id="message"
									required
								></textarea>
							</div>
							<button
								className="contact-us-button"
								type="submit"
								id="contact-us-button"
							>
								Send
							</button>
						</form>
					</div>
				</div>

				<div className="contact-us-column">
					<span
						className="contact-us-bold"
						style={{ color: "#1470af", fontSize: "1.3em" }}
					>
						REGISTER OFFICE ADDRESS:
					</span>
					<br />
					<div className="contact-us-address">
						<span className="contact-us-bold">ANDHRA PRADESH:</span>
						<br />
						Plot No.99, Industrial Park, Singarayakonda, S.Konda Mandal,
						Prakasam Dist, AP, INDIA - 523101.
					</div>
					<div className="contact-us-address">
						<span className="contact-us-bold">TELANGANA:</span>
						<br />
						Plot No.71 N, Vijayapuri Colony Phase-1, Vanasthalipuram, HYD, TS,
						INDIA - 500070.
					</div>
					<div style={{ paddingTop: "60px" }}>
						<div className="contact-us-bold" style={{ paddingBottom: "20px" }}>
							Email us:{" "}
						</div>
						<a
							className="contact-email"
							href="mailto:baig@rowin-roceao.com?subject=**your%20subject%20here**&amp;body=**your%20kind%20words%2Fenquiries%20here**&amp;cc=admin%40rowin-roceao.com"
						>
							baig@rowin-roceao.com
						</a>
						<div style={{ paddingTop: "10px" }} />

						<a
							className="contact-email"
							href="mailto:admin@rowin-roceao.com?subject=**your%20subject%20here**&amp;body=**your%20kind%20words%2Fenquiries%20here**&amp;cc=baig%40rowin-roceao.com"
						>
							admin@rowin-roceao.com
						</a>
					</div>
					<div style={{ paddingTop: "20px" }}>
						<div className="contact-us-bold" style={{ paddingBottom: "20px" }}>
							Call us:{" "}
						</div>
						<a className="contact-phone-number" href="tel:+919393012540">
							+91 93930 12540
						</a>
						<div style={{ paddingTop: "10px" }} />
						<a className="contact-phone-number" href="tel:+919642936786">
							+91 96429 36786
						</a>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
