import React from "react";
import "./AboutUs.css";
import AboutUsOne from "../../static/About-Us-One.png";
import AboutUsOneSmall from "../../static/About-Us-One-small.png";
import AboutUsTwo from "../../static/About-Us-Two.jpg";
import AboutUsTwoSmall from "../../static/About-Us-Two-small.jpg";
import Footer from "../Footer";
import BackgroundImageLoader from "../BackgroundImageLoader";

export default function AboutUs() {
	return (
		<div className="content">
			<div className="top-gap"></div>
			<div className="title">About Us</div>
			<BackgroundImageLoader
				className="banner-one"
				mainImage={AboutUsOne}
				placeholderImage={AboutUsOneSmall}
				backgroundSize="cover"
				backgroundPosition="center"
			>
				<div className="banner-one-text">
					<div className="heading-box">PEOPLE • POLICY • PRICING</div>
					<div className="about-us-columns">
						<div className="column">
							<div className="about-us-big-column-text">PEOPLE</div>
							<div className="about-us-column-text">
								Through customer relation management.
							</div>
						</div>
						<div className="column">
							<div className="about-us-big-column-text">POLICY</div>
							<div className="about-us-column-text">
								Through business ethics, collaborative marketing, advising
								customers with market updates, and market behavior. Keeping in
								mind the present and future business prospects.
							</div>
						</div>
						<div className="column">
							<div className="about-us-big-column-text">PRICING</div>
							<div className="about-us-column-text">
								Offering products at most competitive and commercially prudent
								prices coupled with supportive logistics.
							</div>
						</div>
					</div>
				</div>
			</BackgroundImageLoader>
			<BackgroundImageLoader
				className="banner-two"
				mainImage={AboutUsTwo}
				placeholderImage={AboutUsTwoSmall}
				backgroundSize="cover"
				backgroundPosition="left"
			>
				<div className="banner-two-text-parent">
					<div className="banner-two-text">
						<span
							style={{ color: "#0072BE", fontFamily: "NeueHaasDisplayMedium" }}
						>
							ROWIN
						</span>{" "}
						<span
							style={{ color: "#28B600", fontFamily: "NeueHaasDisplayMedium" }}
						>
							ROCEAO
						</span>{" "}
						IMPEX is a marketing company engaged in Import and Export of Pharma
						Raw Materials & Intermediates, Fine Chemicals, Dye Intermediates,
						Agro Chemicals, Animal Feed Products ETC.
						<br />
						<br />
						We act as bridge and provide complete marketing and sourcing service
						to our customers. We focus on becoming a Value-Added Support Partner
						for all of our clients by delivering solutions that are high on
						quality, cost-effective, and timely after sales service. With our
						perceptive product knowledge and market analysis we were able to
						favoured strategic partner of global as well domestic manufacturers.
					</div>
				</div>
			</BackgroundImageLoader>

			<Footer />
		</div>
	);
}
