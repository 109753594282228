import React, { useState, useEffect } from "react";

function BackgroundImageLoader({
	className,
	mainImage,
	placeholderImage,
	children,
	backgroundPosition = "right",
	backgroundSize = "cover",
  }) {
	const [backgroundImage, setBackgroundImage] = useState(placeholderImage);

	useEffect(() => {
	  const imageToLoad = new Image();
	  imageToLoad.src = mainImage;
	  imageToLoad.onload = () => {
		setBackgroundImage(mainImage);
	  };
	}, [mainImage]);

	return (
	  <div
		className={className}
		style={{
		  backgroundImage: `url(${backgroundImage})`,
		  backgroundPosition: backgroundPosition,
		  backgroundSize: backgroundSize,
		  backgroundRepeat: "no-repeat",
		  transition: 'background-image 0.3s linear',
		}}
	  >
		{children}
	  </div>
	);
  }

  export default BackgroundImageLoader;
