import './Footer.css'
export default function Footer() {
	return (
		<div className="content">
		<footer id="footer">
			<div className="columns">
				<div className="column">
					<div className="location-heading">Location</div>
					<div className="location-body">
						9HV5+WV8, Saraswathi Colony, Uppal, HYD, Telangana State, INDIA -
						500039.
					</div>
				</div>
				<div className="column">
					<div className="hours-heading">Hours</div>
					<div className="hours-timings">
						<div className="hours-timing">
							Monday — Friday
							<div style={{ paddingTop: "10px" }} />
							9 AM — 6 PM
						</div>
						<div className="hours-timing">
							Saturday — Sunday
							<div style={{ paddingTop: "10px" }} />
							9 AM — 1 PM
						</div>
					</div>
				</div>
				<div className="column">
					<div className="contact-heading">Contact</div>
					<div className="contact-emails">
						<a
							className="contact-email"
							href="mailto:baig@rowin-roceao.com?subject=**your%20subject%20here**&amp;body=**your%20kind%20words%2Fenquiries%20here**&amp;cc=admin%40rowin-roceao.com"
						>
							baig@rowin-roceao.com
						</a>
						<div style={{ paddingTop: "10px" }} />

						<a
							className="contact-email"
							href="mailto:admin@rowin-roceao.com?subject=**your%20subject%20here**&amp;body=**your%20kind%20words%2Fenquiries%20here**&amp;cc=baig%40rowin-roceao.com"
						>
							admin@rowin-roceao.com
						</a>
					</div>
				</div>
			</div>
			<div className="copyright">
				ROWIN ROCEAO IMPEX, Copyright © 2020 - 2024, All rights reserved.
			</div>
		</footer>
		</div>
	);
}
