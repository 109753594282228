import React from "react";
import "./OurProducts.css";
import IodineDerivativesChiralProducts from "../../static/products/Iodine-derivative-products.pdf";
import FluorochemicalProducts from "../../static/products/Fluorochemical-Products.pdf";
import APIWhatever from "../../static/products/API Intermediate list.pdf";
import RawMaterials from "../../static/products/Pharma Raw Materials.pdf";
import Footer from "../Footer";

const Product = ({ productName, linkName, pdfUrl }) => {
  const openPDFInNewTab = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="product-row">
      <div className="product-name">{productName}:</div>
      <div className="product-desc" onClick={openPDFInNewTab}>
        Our List of {linkName}
      </div>
    </div>
  );
};

const productsData = [
  { productName: "Export Products", linkName: "Iodine Derivatives & Chiral Products", pdfUrl: IodineDerivativesChiralProducts },
  { productName: "Fluorochemical Products", linkName: "Fluorochemical Products", pdfUrl: FluorochemicalProducts},
  { productName: "API's Intermediates", linkName: "API's Intermediates", pdfUrl: APIWhatever },
  { productName: "Pharma Raw Materials", linkName: "Raw Materials", pdfUrl: RawMaterials },
];

const OurProducts = () => {
  return (
    <div className="content">
      <div className="top-gap"/>
      <div className="products-title">Our Products:</div>
      <div className="products-container">
        {productsData.map((product, index) => (
          <Product
            key={index}
            productName={product.productName}
            linkName={product.linkName}
            pdfUrl={product.pdfUrl}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default OurProducts;
