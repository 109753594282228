import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AboutUs from './components/AboutUs/AboutUs';
import ContactUs from './components/ContactUs/ContactUs';
import HomePage from './components/HomePage/HomePage';
import NavBar from './components/NavBar';
import OurProducts from './components/OurProducts/OurProducts';

import { inject } from '@vercel/analytics';
inject();

function App() {
	return (<>
		<NavBar />
       <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/our-products" element={<OurProducts />} />
          <Route path="/about-us" element={<AboutUs />} />
		  <Route path="/contact-us" element={<ContactUs />} />

       </Routes>
    </>)
}

export default App;
