import React from "react";
import WelcomeBanner from "../../static/WelcomeBanner.png";
import WelcomeBannerSmall from "../../static/WelcomeBanner-small.png";
import OurVision from "../../static/Our-Vision-with-filter.png";
import OurVisionSmall from "../../static/Our-Vision-with-filter-small.png";
import "./HomePage.css";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import ImageLoader from "../ImageLoader";
import BackgroundImageLoader from "../BackgroundImageLoader";

export default function HomePage() {
	return (
		<div className="content">
			<div className="welcome-banner">
				<ImageLoader
					alt="Welcome to Rowin Roceao Impex"
					mainImage={WelcomeBanner}
					placeholderImage={WelcomeBannerSmall}
					width="100%"
					height="100%"
					placeholderSaturation="110%"
					style={{
						objectFit: "cover",
						objectPosition: "left",
					}}
				/>
				<div className="welcome-banner-text">
					<div style={{ fontFamily: "NeueHaasDisplayLight" }}>Welcome to,</div>
					<div style={{ fontFamily: "NeueHaasDisplayBoldItalic" }}>
						ROWIN ROCEAO IMPEX.
					</div>
				</div>
			</div>

			<div className="center-text">
				<div className="center-text-content" id="import-export-text">
					<div className="center-text-title">Import & Export</div>
					<div className="center-text-body">
						We are indulged in imports and exports of pharmaceutical and
						agrochemical intermediates, fine chemicals, specialty chemicals,
						solvents and excipients. We conduct market analysis and marketing
						plan for our principals to target customer and boost customer base
						which ultimately increase sales.
					</div>
				</div>
			</div>

			<BackgroundImageLoader
				className="center-text"
				mainImage={OurVision}
				placeholderImage={OurVisionSmall}
				backgroundPosition="right"
				backgroundSize="cover"
			>
				<div className="center-text-content" id="our-vision-text">
					<div className="center-text-title">Our Vision</div>
					<div className="center-text-body">
						Our vision is to create value through services and strategic
						partnership. We take pride in delivering best in the industry. We
						believe in reaching new horizons and goals in the field of indenting
						and trading by opening a window for smooth business and interaction
						between the different cultures.
					</div>
				</div>
			</BackgroundImageLoader>

			<div className="columns" id="products-touch">
				<div className="our-products">
					<div className="big-text">Our Products</div>
					<div className="list">
						<ul>
							<li>Export Products</li>
							<li>Pharma Raw materials</li>
							<li>API's & Intermediates</li>
							<li>Agrochemicals</li>
						</ul>
					</div>
					<Link to="/our-products">
						<button>View All</button>
					</Link>
				</div>

				<div className="get-in-touch">
					<div className="big-text">Get in Touch</div>
					<div className="thin-body">
						We source Chemical Manufacturers from Japan, Korea, Taiwan, USA,
						Europe and China. We also source and export products from
						Manufacturers in India. You can Contact us below by filling our form
						to email us. Thank you.
					</div>

					<Link to="/contact-us">
						<button>Contact Us</button>
					</Link>
				</div>
			</div>

			<Footer />
		</div>
	);
}
